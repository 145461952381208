import React from 'react';
import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom";

function tick() {
    // Формируем JSX объект element
    const element = (
        <div>
            <h1>Sakh - { new Date().toLocaleTimeString() }</h1>
        </div>
    );

    // Передаем element в DOM , React следит за предыдущим и новым состоянием элемента
    // и заменяет старое значение на новое
    ReactDOM.render(
        element,
        document.getElementById('toor')
    );

}
// Вызываем функцию tick() через секунду
setInterval(tick, 1000);

function App(props) {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
          <div id="toor"></div>
      </header>
    </div>
  );
}

export default App;
